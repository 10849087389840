import React, {useContext, useEffect, useMemo, useState} from 'react';
import axios from 'axios';
import {getPageSource} from '../sources/pageSource';
import {Grid} from '../dimijs/src/lib/components/lists/Grid';
import {
  CrudFilterFieldTypes,
  CrudFilterOperator,
  ISource,
} from '../dimijs/src/lib/sources/source';
import {FieldForm} from '../ui/FieldForm';
import {IFile, IPage} from '../sources/types';
import {IconButton} from '../dimijs/src/lib/components/IconButton';
import {useLocationSearch} from '../dimijs/src/lib/utils/locationSearch';
import styles from './Panel.module.scss';
import {Button} from '../dimijs/src/lib/components/Button';
import {Modal} from '../dimijs/src/lib/components/Modal';
import {NewPageForm} from '../ui/NewPageForm';
import {EditPageForm} from '../ui/EditPageForm';
import {DataCtx} from '../dimijs/src/lib/utils/dataContext';
import {Http} from '../dimijs/src/lib/sources/http';
import {Input} from '../dimijs/src/lib/components/Input';
import classNames from 'classnames';
import gridStyles from '../dimijs/src/lib/components/lists/Grid.module.scss';

export const Panel = () => {
  const {set, params} = useLocationSearch();
  const [panelData, setPanelData] = useState({name: ''});
  const {store} = useContext(DataCtx);
  const pagesSource = getPageSource(store);
  useEffect(() => {
    axios.get('/api/panel').then((res) => {
      setPanelData(res.data);
    });
  }, []);
  const gridPagination = useMemo(
    () => ({queryParamPage: 'page', defaultCountPage: 5, queryParamCountOnPage: 'count'}),
    [],
  );
  const gridColumns = useMemo(() => {
    return [
      {name: '_id'},
      {name: 'title'},
      {name: 'content'},
      {name: 'zones'},
      {name: 'url'},
      {name: 'resolve'},
      {
        name: 'rowOptions',
        Template: (props: {
          item: IPage;
          source: ISource<IPage, string>;
          renderRowIndex: number;
        }) => {
          return (
            <div
              className={classNames(
                styles.flex_row_options,
                props.renderRowIndex % 2 ? gridStyles.odd_row : '',
              )}>
              <IconButton
                iconSetName={'action'}
                iconId={'edit'}
                onClick={() => {
                  set('edit', props.item._id);
                }}
                caption={''}
              />
              <Button
                onClick={() => {
                  set('_id', props.item._id);
                }}
                caption={'Blocks'}
              />
              <Button
                onClick={() => {
                  if (props.item._id) pagesSource.source.delete(props.item._id);
                }}
                caption={'Delete'}
              />
            </div>
          );
        },
      },
    ];
  }, [set]);

  return (
    <div>
      Panel for {panelData.name}<br/>
      {params._id && (
        <Button
          caption="Back"
          onClick={() => {
            set('_id', undefined);
          }}
        />
      )}
      {params._id ? (
        <FieldForm />
      ) : (
        <div>
          <Grid<IPage, string>
            source={pagesSource.source}
            pagination={gridPagination}
            columns={gridColumns}
            header
          />
          <Button
            caption="Add new"
            onClick={() => {
              set('add_new', '1');
            }}
          />
        </div>
      )}
      <Modal
        isOpen={!!params.add_new}
        width={600}
        onClose={() => {
          set('add_new', undefined);
        }}>
        <NewPageForm
          onSave={() => {
            set('add_new', undefined);
          }}
        />
      </Modal>
      <Modal
        isOpen={!!params.edit}
        width={600}
        onClose={() => {
          set('edit', undefined);
        }}>
        <EditPageForm
          _id={params.edit}
          onSave={() => {
            set('edit', undefined);
          }}
          onCancel={() => {
            set('edit', undefined);
          }}
        />
      </Modal>
      {params._id && <div className={styles.preview}></div>}
    </div>
  );
};
