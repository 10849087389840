// LICENSE_CODE ZON
'use strict'; /*jslint ts:true, react: true*/
import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import styles from './MultiSelect.module.scss';
import cn from 'classnames';
import {Input, IInputProps} from './Input';
import {
  CrudAggregation,
  CrudFilterFieldTypes,
  CrudFilterOperator,
  ICrudFilter,
  ICrudSorting,
  ISource,
} from '../sources/source';
import {useSource} from './lists/useSource';
import {Icon} from './Icon';
import {Popup} from './Popup';
import {Base} from './lists/Base';
import {IconButton} from './IconButton';

type RemoveRedefinedFieldFromInput = {
  [Property in keyof IInputProps as Exclude<
    Property,
    'value' | 'onChange'
  >]: IInputProps[Property];
};

export interface IMultiSelectProps<ItemType, KeyFieldType>
  extends RemoveRedefinedFieldFromInput {
  source: ISource<ItemType, KeyFieldType>;
  value: KeyFieldType[];
  filterFields: (keyof ItemType)[];
  onChange: (newValue: KeyFieldType[]) => void;
  onValueRender: (item: ItemType, selected: boolean) => string;
}

export const MultiSelect: <ItemType, KeyFieldType>(
  props: IMultiSelectProps<ItemType, KeyFieldType>,
) => JSX.Element = <ItemType, KeyFieldType>(
  props: IMultiSelectProps<ItemType, KeyFieldType>,
) => {
  const {
    source,
    className,
    value,
    onValueRender,
    onFocus,
    onBlur,
    filterFields,
    ...inputProps
  } = props;
  const [filter, setFilter] = useState([] as ICrudFilter<ItemType>[]);
  const filterForValues = useMemo(() => [], []);
  const [inputValueForFilter, setInputValueForFilter] = useState('');
  const sorting = useMemo(() => [] as ICrudSorting<ItemType>[], []);
  const pagination = useMemo(
    () => ({
      page: 0,
      countOnPage: 100,
    }),
    [],
  );
  const {list} = useSource(props.source, filter, pagination, sorting);
  const {list: listForValues} = useSource(
    props.source,
    filterForValues,
    pagination,
    sorting,
  );
  const [focused, setFocused] = useState(false);
  const [opened, setOpened] = useState(false);
  const rootEl = useRef<HTMLDivElement>(null);
  const onChangeInternal = (new_value: ItemType) => {
    props.onChange([...value, new_value[source.keyField] as KeyFieldType]);
    setOpened(false);
  };
  useEffect(() => {
    let filterArray: ICrudFilter<ItemType>[] = [];
    if (inputValueForFilter) {
      filterFields.forEach((field) => {
        filterArray.push({
          field: field,
          value: inputValueForFilter,
          type: CrudFilterFieldTypes.Field,
          operator: CrudFilterOperator.LIKE,
        });
      });
    }
    if (filterArray.length > 0) {
      filterArray = [
        {
          type: CrudFilterFieldTypes.Aggregation,
          filter: filterArray,
          aggregation: CrudAggregation.OR,
        },
      ];
    }
    props.value.forEach((itemId) => {
      filterArray.push({
        field: source.keyField,
        value: itemId as string,
        type: CrudFilterFieldTypes.Field,
        operator: CrudFilterOperator.NOTEQ,
      });
    });
    setFilter(filterArray);
  }, [inputValueForFilter, props.value]);
  useEffect(() => {
    if (focused) {
      setOpened(true);
    }
  }, [focused, inputValueForFilter]);
  const selectedItems = useMemo(() => {
    if (props.value === undefined) return;
    return listForValues.filter(
      (d) => props.value.indexOf(d[source.keyField] as KeyFieldType) > -1,
    );
  }, [props.value, listForValues]);
  return (
    <div
      className={cn(
        styles.dropdownContainer,
        className,
        focused && styles.focused,
        opened ? styles.opened : styles.closed,
      )}
      ref={rootEl}
      onMouseDown={() => {
        if (focused && opened) {
          setOpened(false);
        }
        if (focused && !opened) {
          setOpened(true);
        }
      }}
      style={
        selectedItems
          ? ({
              '--items': selectedItems.length,
            } as any)
          : {}
      }>
      <div className={styles.flexValues}>
        {selectedItems?.map((item) => {
          return (
            <div className={styles.selectedItem}>
              <div className={styles.renderedSelectedItem}>
                {onValueRender(item, true)}
              </div>
              <IconButton
                size={'sm'}
                caption=''
                noPadding
                iconSetName={'action'}
                iconId={'delete'}
                color={'noBorder'}
                onClick={() => {
                  props.onChange(
                    value.filter((i) => i !== item[source.keyField]),
                  );
                }}
              />
            </div>
          );
        })}
      </div>
      <Input
        {...inputProps}
        value={inputValueForFilter}
        onChange={setInputValueForFilter}
        selectOnFocus
        iconAfter={
          <div className={cn(styles.chevronDown, opened && styles.rotated)}>
            <Icon
              setName={'navigation'}
              id={'chevronDown'}
              width={16}
              height={16}
            />
          </div>
        }
        className={styles.inputInDropdown}
        onFocus={(ev) => {
          if (props.onFocus) props.onFocus(ev);
          setFocused(true);
        }}
        onBlur={(ev) => {
          if (props.onBlur) props.onBlur(ev);
          setFocused(false);
          setOpened(false);
          if (inputValueForFilter && list && list.length === 1) {
            onChangeInternal(list[0]);
            setInputValueForFilter('');
          }
        }}
      />
      {rootEl.current && (
        <Popup
          isOpen={opened}
          onClose={() => setOpened(false)}
          anchorElement={rootEl.current}
          parent={rootEl.current.querySelector('input') as HTMLInputElement}
          strategy="bind bind auto bind"
          offsetTop={rootEl.current.offsetHeight + 4}
          className={styles.dropdownPopup}>
          <Base
            source={props.source}
            filter={filter}
            pagination={pagination}
            sorting={sorting}
            RowTemplate={(row) => {
              return (
                <div
                  className={styles.row}
                  tabIndex={0}
                  onMouseDown={() => {
                    onChangeInternal(row.item);
                  }}>
                  {onValueRender(row.item, false)}
                </div>
              );
            }}
          />
        </Popup>
      )}
    </div>
  );
};
