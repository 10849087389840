export interface IPage {
  _id?: string;
  title: string;
  zones: string;
  content?: string;
  parent_id?: string;
  created?: number;
  createdBy?: string;
  updated?: number;
  updatedBy?: string;
  resolve: string;
  url: string;
}

export enum BlockType {
  header = 'header',
  mobileBurger = 'mobileBurger',
  link = 'link',
  container = 'container',
  image = 'image',
  paragraph = 'paragraph',
  heading = 'heading',
  spacer = 'spacer',
  grid = 'grid',
  login = 'login',
  lazy = 'lazy',
  left_menu = 'left_menu',
  /* TODO:: site-specific blocks */

  /* END site-specific blocks */
}

export interface IBlockType {
  _id: BlockType;
  title: string;
}

export interface IBlock {
  _id?: string;
  label: string;
  blockType: BlockType;
  width: string;
  height: string;
  parent?: string;
  page: string;
  zones: string;
  order: number;
  props: {[propname: string]: any};
}


export interface ICollection {
  _id?: string;
  name: string;
  fields: {[propname: string]: 'string'|'number'|'date'};
}

export interface ICollectionItem {
  _id?: string;
  [propname: string]: string|number|undefined,
}

export interface IUser {
  _id?: string;
  login: string;
  password: string;
  role: string;
}

export interface IFile {
  _id?: string;
  filename: string;
}

export interface IZone {
  id: string;
}

export interface IDocument extends ICollectionItem {
  title: string;
  priority: number;
  description: string;
  zones: string;
  assignee: string;
  state: string;
}
