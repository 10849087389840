import {Http} from '../dimijs/src/lib/sources/http';
import {ICrudFilter, ICrudPagination, ICrudSorting} from '../dimijs/src/lib/sources/source';
import { Static } from '../dimijs/src/lib/sources/static';
import {BlockType, IBlock, IBlockType, IPage} from './types';

export const getBlocksSource = (store:any)=>{
  return store.blocksSource as {
    inited: boolean,
    source: Http<IBlock, string>,
    sorting: ICrudSorting<IBlock>[],
    filter: ICrudFilter<IBlock>[],
    pagination: ICrudPagination,
  };
};

export const initBlocksSource = (store:any, initProps)=>{
  store.blocksSource = {
    inited: false,
    source: null as any,
    sorting: [{field: 'order'}],
    filter: [],
    pagination: {page: 0, countOnPage: 100},
  } as {
    inited: boolean,
    source: Http<IBlock, string>,
    sorting: ICrudSorting<IBlock>[],
    filter: ICrudFilter<IBlock>[],
    pagination: ICrudPagination,
  };
  store.blocksSource.source = new Http<IBlock, string>('_id',
    // @ts-ignore
    initProps && initProps.blocks ? {
      data: {
        // @ts-ignore
        data: initProps.blocks.data,
      },
      filter: initProps.blocks.filter,
      pagination: store.blocksSource.pagination,
      sorting: store.blocksSource.sorting,
    } : undefined);
    store.blocksSource.source.httpEndPoint = '/api/blocks';
}

export const blocksTypes = new Static<IBlockType, string>('_id', {
  data: {
    data: [
      {_id: BlockType.header, title: 'Header'},
      {_id: BlockType.mobileBurger, title: 'Mobile Burger'},
      {_id: BlockType.link, title: 'Link'},
      {_id: BlockType.container, title: 'Container'},
    ],
  },
});
