import React, {useContext, useMemo} from 'react';
import {IBlockProps} from './Blocks';
import {ICrudFilter, ISource} from '../../dimijs/src/lib/sources/source';
import {IPage} from '../../sources/types';
import {getPageSource} from '../../sources/pageSource';
import {DataCtx} from '../../dimijs/src/lib/utils/dataContext';
import {Base} from '../../dimijs/src/lib/components/lists/Base';
import {Link} from 'react-router-dom';

export interface IAdditionalLeftMenuBlockProps {
  filter: ICrudFilter<IPage>[]
  className: string;
}
export interface ILeftMenuBlockProps extends IBlockProps, IAdditionalLeftMenuBlockProps {
  
}

export const LeftMenu = (props:ILeftMenuBlockProps)=>{
    const pagination = useMemo(
      () => ({page: 0, countOnPage: 50}),
      []
    );
    const {store} = useContext(DataCtx);
    const pagesSource = getPageSource(store);
    return <div style={{width:props.width}} className={props.className}>
      <Base source={pagesSource.source} filter={props.filter}
        pagination={pagination} RowTemplate={rowTemplateProps=>{
          return <div>
            <Link to={rowTemplateProps.item.url}>
              {rowTemplateProps.item.title}</Link>
          </div>;
        }}/>
    </div>;
};
