import {useContext, useMemo} from 'react';
import {useLocation} from 'react-router';
import {useNavigate} from 'react-router-dom';
import {DataCtx} from './dataContext';

export const useLocationSearch = () => {
  const {store} = useContext(DataCtx);
  const {search, pathname} = useLocation();
  const navigate = useNavigate();
  // URLSearchParams is not available in IE11
  const searchParams = useMemo(() => {
    if (!store.searchObject)
      store.searchObject = {} as {[propname: string]: string};
    const elements = search.slice(1).split('&');
    elements.forEach((element) => {
      const data = element.split('=');
      if (data[0])
        store.searchObject[data[0]] = data[1];
    });

    return {
      params: store.searchObject,
      set: (name: string, value?: string | undefined) => {
        if (value === undefined) delete store.searchObject[name];
        else store.searchObject[name] = value;
        navigate(
          `${pathname}?${Object.keys(store.searchObject)
            .map((key) => {
              return `${key}=${store.searchObject[key]}`;
            })
            .join('&')}`
        );
      },
      getLinkWithParam: (name: string, value?: string | undefined) => {
        const temp = {...store.searchObject};
        if (value === undefined) delete temp[name];
        else temp[name] = value;
        return `${pathname}?${Object.keys(temp)
          .map((key) => {
            return `${key}=${temp[key]}`;
          })
          .join('&')}`;
      },
    };
  }, [search, pathname, navigate]);
  return searchParams;
};
