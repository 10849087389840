import React, {useContext, useEffect, useState} from 'react';
import {Input} from '../dimijs/src/lib/components/Input';
import {Button} from '../dimijs/src/lib/components/Button';
import {getPageSource} from '../sources/pageSource';
import {IPage} from '../sources/types';
import {ICrudStatus} from '../dimijs/src/lib/sources/source';
import {DataCtx} from '../dimijs/src/lib/utils/dataContext';
import {MultiSelect} from '../dimijs/src/lib/components/MultiSelect';
import {zonesSource} from '../sources/zonesSource';

interface IEditPageFormProps {
  onSave: ()=>void;
  onCancel: ()=>void;
  _id: string;
}

export const EditPageForm = (props:IEditPageFormProps)=>{
  const {store} = useContext(DataCtx);
  const {_id} = props;
  const [item, setItem] = useState<IPage|undefined>();
  const [title, setTitle] = useState(item?.title || '');
  const [content, setContent] = useState(item?.content || '');
  const [resolve, setResolve] = useState(item?.resolve || '');
  const [url, setUrl] = useState(item?.url || '');
  const [zones, setZones] = useState(item?.zones || '');
  const [zonesArr, setZonesArr] = useState<string[]>(zones.split(','));
  useEffect(()=>{
    if (!_id) {
      setItem(undefined);
      return;
    }
    getPageSource(store).source.read(_id).then(data=>{
      if ((data as ICrudStatus).errorCode) {
        setItem(undefined);
        return;
      }
      setItem(data as IPage);
      setTitle((data as IPage).title);
      setContent((data as IPage).content || '');
      setResolve((data as IPage).resolve || '');
      setZones((data as IPage).zones || '');
      setUrl((data as IPage).url || '');
      setZonesArr(((data as IPage).zones || '').split(','));
    });
  }, [_id]);
  return <div>
    <Input label="title" value={title} onChange={setTitle} />
    <Input label="content" value={content} onChange={setContent} />
    <Input label="resolve" value={resolve} onChange={setResolve} />
    <Input label="url" value={url} onChange={setUrl} />
    <MultiSelect
      label={'zones'}
      source={zonesSource}
      filterFields={['id', 'title']}
      value={zonesArr}
      onChange={newValue => {
        setZones(newValue.filter(Boolean).join(','));
        setZonesArr(newValue);
      }}
      onValueRender={item => item.title||item.id}
    />
    <Button
      caption="Save"
      onClick={() => {
        getPageSource(store).source.update({_id, title, content, resolve, zones, url});
        props.onSave();
      }}
    />
    <Button caption='Cancel' onClick={()=>{
      props.onCancel();
    }}/>
  </div>;
};
