import React, {Fragment, useEffect, useState} from 'react';
import {createPortal} from 'react-dom';
import {useScreen} from '../utils/useScreen';
import styles from './Modal.module.scss';
import cn from 'classnames';
interface IModalProps {
  children: JSX.Element;
  isOpen: boolean;
  contentWhenOpen?: boolean;
  width: number;
  onOverlayClick?: () => void;
  onClose?: () => void;
}
export const Modal = (props: IModalProps) => {
  const [contentVisible, setContentVisible] = useState(
    !props.contentWhenOpen || (props.contentWhenOpen && props.isOpen)
  );
  const {height} = useScreen();
  const [cssVars, setCssVars] = useState({} as any);
  const [state, setState] = useState<{el?: HTMLDivElement}>({el: undefined});
  useEffect(() => {
    const modal_root = document.getElementById('modalRoot');
    const el = document.createElement('div');
    if (!modal_root) return;
    modal_root.appendChild(el);
    setState({el});
    return () => {
      el.remove();
    };
  }, []);
  useEffect(() => {
    if (props.isOpen) {
      document.body.classList.add('global-no-body-scroll');
      document.documentElement.classList.add('global-no-body-scroll');
    } else {
      document.body.classList.remove('global-no-body-scroll');
      document.documentElement.classList.remove('global-no-body-scroll');
    }
    if (props.contentWhenOpen) {
      if (props.isOpen) setContentVisible(true);
      else setTimeout(() => setContentVisible(false), 300);
    }
  }, [props.isOpen]);
  useEffect(() => {
    setCssVars({'--app-height': `${height}px`});
  }, [height]);
  const showContent = contentVisible || props.isOpen;
  if (!state.el) return <Fragment />;
  return createPortal(
    <div
      onClick={props.onOverlayClick || props.onClose}
      style={cssVars}
      className={cn(styles.modalOverlay, props.isOpen && styles.opened)}
    >
      <div
        className={cn(styles.modalWindow, styles.modalWindowShadow)}
        style={{width: `${props.width}px`}}
        onClick={event=>event.stopPropagation()}
      >
        {showContent ? props.children : null}
      </div>
    </div>,
    state.el
  );
};
