import React, {useContext, useState} from 'react';
import {Input} from '../../dimijs/src/lib/components/Input';
import {Button} from '../../dimijs/src/lib/components/Button';
import {useUserSource} from '../../sources/useUserSource';
import axios from 'axios';
import {IBlockProps} from './Blocks';
import styles from './Login.module.scss';
import classnames from 'classnames';
import {DataCtx} from '../../dimijs/src/lib/utils/dataContext';

const auth = async (username, password) => {
  try {
    await axios.get('/authenticate', {auth: {username, password}});
    return true;
  } catch (e) {
    return false;
  }
};

export interface IAdditionalLoginBlockProps {
  loginTitle: string;
  signupTitle: string;
  defaultSlide?: string;
}
export interface ILoginBlockProps
  extends IBlockProps,
    IAdditionalLoginBlockProps {}

export const Login = (props: ILoginBlockProps) => {
  const [slide, setSlide] = useState(props.defaultSlide || 'login');
  const [login, setLogin] = useState('');
  const [pass, setPass] = useState('');
  const userSource = useUserSource();
  const {initProps} = useContext(DataCtx);
  if (initProps.user)
    return null;
  return (
    <div className={classnames(styles.slider, 'relative')}>
      <div
        className={classnames(
          styles.flexSlides,
          slide === 'registration' && styles.move,
        )}>
        <div className={classnames(styles.slide)}>
          <h3>{props.loginTitle}</h3>
          <form onSubmit={(e) => e.preventDefault()}>
            <Input value={login} onChange={setLogin} label={'Login'} />
            <Input value={pass} onChange={setPass} label={'Password'}
              type='password'/>
            <Button
              caption="Login"
              type={'submit'}
              color={'primary'}
              onClick={() => {
                auth(login, pass).then(result => {
                  if (result) window.location.reload();
                });
              }}
            />
          </form>
          <p>
            Don't have an account?{' '}
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setSlide('registration');
              }}>
              Register
            </a>
          </p>
        </div>
        <div className={classnames(styles.slide)}>
          <h3>{props.signupTitle}</h3>
          <form onSubmit={(e) => e.preventDefault()}>
            <Input value={login} onChange={setLogin} label={'Login'} />
            <Input value={pass} onChange={setPass} label={'Password'} />
            <Button
              caption="Register"
              color={'primary'}
              onClick={() => {
                userSource.create([{login, pass}]).then((res) => {
                  if (res.errorCode) {
                  }
                });
              }}
            />
          </form>
          <p>
            Already have an account?{' '}
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setSlide('login');
              }}>
              Login
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};
