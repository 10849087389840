import React, {useEffect, useRef} from 'react';
import styles from './Input.module.scss';
import cn from 'classnames';

export interface IInputProps {
  value: string | number;
  onChange: (value: string) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement, Element>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement, Element>) => void;
  label?: string;
  className?: string;
  placeholder?: string;
  name?: string;
  title?: string;
  disabled?: boolean;
  type?: 'number'|'password';
  size?: 'sm' | 'lg';
  selectOnFocus?: boolean;
  iconAfter?: JSX.Element;
  autoFocus?: boolean;
}
export const Input: (props: IInputProps) => JSX.Element = (props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (props.autoFocus && inputRef.current) inputRef.current.focus();
  }, [props.autoFocus]);
  return (
    <div
      className={cn(
        styles.root,
        props.className,
        props.size && styles[`input--${props.size}`],
      )}>
      {props.label && <label>{props.label}</label>}
      <input
        ref={inputRef}
        type={props.type}
        value={props.value}
        name={props.name}
        placeholder={props.placeholder}
        title={props.title}
        aria-label={props.title || props.name}
        onChange={(e) => {
          props.onChange(e.target.value);
        }}
        disabled={props.disabled}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
      />
      {props.iconAfter ? (
        <div className={cn(styles.iconAfter, props.label && styles.withLabel)}>
          {props.iconAfter}
        </div>
      ) : null}
    </div>
  );
};
