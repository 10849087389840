import React, {Fragment, useContext, useEffect, useMemo, useState} from 'react';
import {Input} from '../dimijs/src/lib/components/Input';
import {Button} from '../dimijs/src/lib/components/Button';
import {BlockType, IBlock, IPage} from '../sources/types';
import {
  CrudEvents,
  CrudFilterFieldTypes,
  ICrudFilter,
  ICrudStatus,
  ISource,
} from '../dimijs/src/lib/sources/source';
import {useLocationSearch} from '../dimijs/src/lib/utils/locationSearch';
import {Grid} from '../dimijs/src/lib/components/lists/Grid';
import {IconButton} from '../dimijs/src/lib/components/IconButton';
import {getBlocksSource} from '../sources/blocksSource';
import {JsonForm} from './JsonForm';
import {Static} from '../dimijs/src/lib/sources/static';
import {DataCtx} from '../dimijs/src/lib/utils/dataContext';
import {zonesSource} from '../sources/zonesSource';
import gridStyles from '../dimijs/src/lib/components/lists/Grid.module.scss';

interface IBlockFormProps {
  page: string;
  id?: string;
  onSave: () => void;
  onCancel: () => void;
}

const blockTypesSource = new Static<any, string>('id', {
  data: {
    data: [
      {id: 'header', title: 'header'},
      {id: 'mobileBurger', title: 'mobileBurger'},
      {id: 'link', title: 'link'},
      {id: 'grid', title: 'grid'},
      {id: 'image', title: 'image'},
      {id: 'container', title: 'container'},
      {id: 'paragraph', title: 'paragraph'},
      {id: 'heading', title: 'heading'},
      {id: 'spacer', title: 'spacer'},
      {id: 'login', title: 'Login/Signup form'},
      {id: 'lazy', title: 'Lazy-loading component'},
      {id: 'left_menu', title: 'Left Menu'},
      /* TODO:: site-specific blocks */

      /* END site-specific blocks */
    ],
  },
});

export const BlockForm = (props: IBlockFormProps) => {
  const {params, set} = useLocationSearch();
  const [item, setItem] = useState<IBlock | undefined>();
  const {store} = useContext(DataCtx);
  const blocksSource = getBlocksSource(store);
  const nullBlock = useMemo(
    () => ({
      _id: '',
      label: '',
      blockType: BlockType.container,
      width: '',
      height: '',
      zones: 'guest',
      order: 0,
      page: props.page,
      props: {},
    }),
    [props.page],
  );
  const [itemState, setItemState] = useState<IBlock>(nullBlock);
  const gridPagination = useMemo(
    () => ({queryParamPage: 'page', defaultCountPage: 5}),
    [],
  );
  const gridFiltration = useMemo<ICrudFilter<IBlock>[]>(() => {
    return [
      {
        field: 'parent' as const,
        value: props.id,
        type: CrudFilterFieldTypes.Field,
      },
    ];
  }, [props.id]);
  const blocksSourceClone = useMemo(() => {
    const newSource = blocksSource.source.clone();
    newSource.permanentFilter = [
      {
        field: 'page' as const,
        value: props.page,
        type: CrudFilterFieldTypes.Field,
      },
    ];
    return newSource;
  }, [props.page, blocksSource.source]);
  const gridColumnsInner = useMemo(() => {
    return [
      {
        name: 'label',
        Template: (props: {
          item: IBlock;
          source: ISource<IBlock, string>;
          renderRowIndex: number;
        }) => {
          return (
            <div className={props.renderRowIndex % 2 ? gridStyles.odd_row : ''}>
              {props.item.label || props.item._id}
            </div>
          );
        },
      },
      {name: 'blockType'},
      {name: 'zones'},
      {name: 'order'},
      {
        name: 'props',
        Template: (props: {
          item: IBlock;
          source: ISource<IBlock, string>;
          renderRowIndex: number;
        }) => {
          return (
            <div className={props.renderRowIndex % 2 ? gridStyles.odd_row : ''}>
              {JSON.stringify(props.item?.props)}
            </div>
          );
        },
      },
      {
        name: 'rowOptions',
        Template: (props: {
          item: IBlock;
          source: ISource<IBlock, string>;
          renderRowIndex: number;
        }) => {
          return (
            <div className={props.renderRowIndex % 2 ? gridStyles.odd_row : ''}>
              <IconButton
                iconSetName={'action'}
                iconId={'search'}
                onClick={() => {
                  set('_block_id', props.item._id);
                }}
                caption={''}
              />
              <IconButton
                iconSetName={'action'}
                iconId={'delete'}
                onClick={() => {
                  if (props.item._id) props.source.delete(props.item._id);
                }}
                caption={''}
              />
            </div>
          );
        },
      },
    ];
  }, [set]);
  useEffect(() => {
    if (!props.id) {
      setItem(undefined);
      setItemState(nullBlock);
      return;
    }
    blocksSource.source.read(props.id).then((data) => {
      if ((data as ICrudStatus).errorCode) {
        setItem(undefined);
        return;
      }
      setItemState(data as IBlock);
      setItem(data as IBlock);
    });
  }, [props.id, nullBlock]);
  const fields = useMemo(
    () => [
      {
        name: 'blockType',
        type: 'dropdown' as const,
        values: blockTypesSource,
      },
      {name: 'label', type: 'string' as const},
      {name: 'width', type: 'string' as const},
      {name: 'height', type: 'string' as const},
      {name: 'order', type: 'number' as const},
      {
        name: 'parent',
        type: 'dropdown' as const,
        values: blocksSourceClone as ISource<any, string>,
        viewField: 'label',
      },
      {name: 'page', type: 'string' as const},
      {name: 'zones', type: 'multiselect' as const, values: zonesSource},
      {name: 'props', type: 'json' as const},
    ],
    [],
  );
  return (
    <div>
      {props.id ? <span>Edit {props.id}</span> : <span>Create new</span>}
      <JsonForm<IBlock>
        value={itemState}
        onChange={setItemState}
        fields={fields}
      />
      <Button
        caption="Save"
        onClick={() => {
          if (!item) {
            blocksSource.source.create([itemState]);
          } else {
            blocksSource.source.update({
              ...itemState,
              _id: item._id,
            });
          }
          props.onSave();
          setItem(undefined);
          setItemState(nullBlock);
        }}
      />
      <Button
        caption="Cancel"
        onClick={() => {
          props.onCancel();
          setItem(undefined);
          setItemState(nullBlock);
        }}
      />

      {item ? (
        <div>
          <h2>Inner blocks</h2>
          <Grid<IBlock, string>
            source={blocksSource.source}
            defaultSorting={blocksSource.sorting}
            pagination={gridPagination}
            columns={gridColumnsInner}
            defaultFilter={gridFiltration}
            header
          />
        </div>
      ) : null}
    </div>
  );
};
