import {Static} from "../dimijs/src/lib/sources/static";
import {IZone} from "./types";

// TODO:: JsonForm has an error in type description
export const zonesSource = new Static<any, string>('id', {
  data: {
    data: [{
      id: 'guest'
    }, {
      id: 'user',
    }, {
      id: 'admin',
    }],
  },
});
