import {Suspense, lazy, useMemo} from 'react';
import {BlockType, IBlock} from '../../sources/types';
import {Header} from './Header';
import {IAdditionalLinkBlockProps, Link} from './Link';
import {GridBlock} from './GridBlock';
import {IAdditionalImageBlockProps, Image} from './Image';
import {Login} from './Login';
import {LeftMenu} from './LeftMenu';

/* TODO:: site-specific blocks import */

/* END site-specific blocks import */

export interface IBlockProps {
    innerBlocks?: IBlock[];
    blocksOnPage?: IBlock[];
    width?: string;
    height?: string;
}

export interface IBlockResolverProps {
    block: IBlock;
    blocksOnPage?: IBlock[];
}

export interface IBlocksResolverProps {
    parent?: string;
    blocksOnPage?: IBlock[];
}

export const BlocksResolver = (props: IBlocksResolverProps) => {
    const filteredBlocks = useMemo<IBlock[]>(() => {
        const filtered = props.blocksOnPage?.filter((block) => {
            return block.parent === props.parent;
        });
        return filtered || [];
    }, [props.blocksOnPage]);
    return (
        <>
            {filteredBlocks.map((block) => {
                return (
                    <BlockResolver
                        key={block._id}
                        block={block}
                        blocksOnPage={props.blocksOnPage}
                    />
                );
            })}
        </>
    );
};

export const BlockResolver = (props: IBlockResolverProps) => {
    const innerBlocks = useMemo<IBlock[]>(() => {
        const filtered = props.blocksOnPage?.filter((block) => {
            return block.parent === props.block._id;
        });
        return filtered || [];
    }, [props.blocksOnPage]);
    const blockProps = {innerBlocks};
    if (props.block.blockType === BlockType.header)
        return <Header {...props.block}>
          <BlocksResolver
              blocksOnPage={props.blocksOnPage}
              parent={'' + props.block._id}
          />
        </Header>;
    if (props.block.blockType === BlockType.link) {
        return (
            <Link
                {...blockProps}
                {...props.block}
                {...(props.block.props as IAdditionalLinkBlockProps)}
            />
        );
    }
    if (props.block.blockType === BlockType.grid)
        return <GridBlock {...blockProps} {...props.block.props} />;
    if (props.block.blockType === BlockType.container) {
        return (
            <div style={props.block.props ? props.block.props.css : {}}
              className={props.block.props ? props.block.props.className : ''}>
                <BlocksResolver
                    blocksOnPage={props.blocksOnPage}
                    parent={'' + props.block._id}
                />
            </div>
        );
    }
    if (props.block.blockType === BlockType.image)
        return (
            <Image
                {...props.block}
                {...(props.block.props as IAdditionalImageBlockProps)}
            />
        );
    if (props.block.blockType === BlockType.paragraph)
        return <p dangerouslySetInnerHTML={{__html: props.block.props.html}} />;
    if (props.block.blockType === BlockType.heading) {
        const Heading = props.block.props.heading;
        return (
            <Heading
                dangerouslySetInnerHTML={{__html: props.block.props.html}}
            />
        );
    }
    if (props.block.blockType === BlockType.spacer)
        return <div style={{width: '100%', height: props.block.height}} />;

    if (props.block.blockType === BlockType.login)
        return <Login {...props.block} {...props.block.props as any}/>
    
    if (props.block.blockType === BlockType.lazy) {
        const Component = lazy(() => import(`../${props.block.props.component}`));
        return <Suspense fallback={<div>Loading...</div>}>
          <Component {...props.block} {...props.block.props as any}/>
        </Suspense>;
    }

    if (props.block.blockType === BlockType.left_menu)
        return <LeftMenu {...props.block} {...props.block.props as any}/>
    /* TODO:: site-specific blocks */

    /* END site-specific blocks */
    return <></>;
};
