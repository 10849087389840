import React, {useContext, useState} from 'react';
import {Input} from '../dimijs/src/lib/components/Input';
import {Button} from '../dimijs/src/lib/components/Button';
import {getPageSource} from '../sources/pageSource';
import {DataCtx} from '../dimijs/src/lib/utils/dataContext';
import {MultiSelect} from '../dimijs/src/lib/components/MultiSelect';
import {zonesSource} from '../sources/zonesSource';

interface INewPageFormProps {
  onSave: ()=>void;
}

export const NewPageForm = (props:INewPageFormProps)=>{
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [resolve, setResolve] = useState('');
  const [zones, setZones] = useState('');
  const [url, setUrl] = useState('');
  const [zonesArr, setZonesArr] = useState<string[]>([]);
  const {store} = useContext(DataCtx);
  return <div>
    <Input label="title" value={title} onChange={setTitle} />
    <Input label="content" value={content} onChange={setContent} />
    <Input label="resolve" value={resolve} onChange={setResolve} />
    <Input label="url" value={url} onChange={setUrl} />
    <MultiSelect
      label={'zobes'}
      source={zonesSource}
      filterFields={['id', 'title']}
      value={zonesArr}
      onChange={newValue => {
        setZones(newValue.filter(Boolean).join(','));
        setZonesArr(newValue);
      }}
      onValueRender={item => item.title||item.id}
    />
    <Button
      caption="Save"
      onClick={() => {
        getPageSource(store).source.create([{title, content, resolve, zones, url}]);
        setTitle('');
        setContent('');
        setResolve('');
        setZones('');
        setUrl('');
        props.onSave();
      }}
    />
  </div>;
};
