import React from 'react';
import styles from './dimijs/src/lib/theme/base.module.scss';
import cn from 'classnames';

import {Routes, Route} from 'react-router-dom';
import {FormRegister} from './pages/FormRegister';
import {FormLogin} from './pages/FormLogin';
import {Panel} from './pages/Panel';
import {Page} from './pages/Page';
import modalStyles from './dimijs/src/lib/components/Modal.module.scss';
import {Collections} from './pages/panel/Collections';
import {DataContext} from './dimijs/src/lib/utils/dataContext';
import {Users} from './pages/panel/Users';

function App(props) {
  const MainRouter = props.MainRouter;
  return (
    <DataContext initProps={props.initProps} store={props.store}
      ssrDataCollector={props.ssrDataCollector}>
      <MainRouter {...props.routerProps}>
        <div className={cn(styles.root)}>
          <Routes>
            <Route path="/form" element={<FormRegister />} />
            <Route path="/login" element={<FormLogin />} />
            <Route path="/panel" element={<Panel />} />
            <Route path="/panel/collections" element={<Collections />} />
            <Route path="/panel/users" element={<Users />} />
            <Route path="/p/*" element={<Page />} />
            <Route path="/" element={<Page />} />
          </Routes>
          <div className={modalStyles.modalRoot} id={'modalRoot'}></div>
        </div>
      </MainRouter>
    </DataContext>
  );
}

export default App;
