import React, {useContext, useEffect, useMemo, useState} from 'react';
import {Grid} from '../../dimijs/src/lib/components/lists/Grid';
import {Pagination} from '../../dimijs/src/lib/components/lists/Pagination';

import {ICrudStatus, ISource} from '../../dimijs/src/lib/sources/source';
import { useLocationSearch } from '../../dimijs/src/lib/utils/locationSearch';
import { Button } from '../../dimijs/src/lib/components/Button';
import { Modal } from '../../dimijs/src/lib/components/Modal';
import { JsonForm } from '../../ui/JsonForm';
import {DataCtx} from '../../dimijs/src/lib/utils/dataContext';
import {IUser, useUserSource} from '../../sources/useUserSource';

const newUser:IUser = {
  login: '',
  pass: '',
  role: '',
}

export const Users = () => {
  const {set, params} = useLocationSearch();
  const [selectedUser, setSelectedUser] = useState<IUser>({
    ...newUser,
  });
  const [formOpened, setFormOpened] = useState(false);
  const {store} = useContext(DataCtx);
  const usersSource = useUserSource();
  const pagination = useMemo(() => {
    return {
      defaultCountPage: 10,
      queryParamCountOnPage: 'countUsersOnPage',
      queryParamPage: 'pageUsers',
    };
  }, []);
  const columns = useMemo(() => {
    return [
      {name: '_id'},
      {name: 'login', Template: (props: {
        item: IUser;
        source: ISource<IUser, string>;
      }) => {
        return (
          <div>
            <Button caption={props.item.login} onClick={()=>{
              set('selectedUser', props.item._id);
            }}/>
          </div>
        );
      },},
      {name: 'pass'},
      {name: 'role'},
      {name: 'updatedBy'},
      {name: 'updated'},
    ];
  }, []);
  const editFields = useMemo(()=>([
    {name: 'login', type: 'string' as const},
    {name: 'role', type: 'string' as const},
  ]), []);
  const createFields = useMemo(()=>([
    {name: 'login', type: 'string' as const},
    {name: 'pass', type: 'string' as const},
    {name: 'role', type: 'string' as const},
  ]), []);
  useEffect(()=>{
    if (!params.selectedUser) {
      return;
    }
    usersSource.read(params.selectedUser).then(data=>{
      if ((data as ICrudStatus).errorCode) {
        setSelectedUser({...newUser});
        return;
      }
      if (data!==null) {
        setSelectedUser(data as IUser);
        setFormOpened(true);
      }
    });
  }, [params.selectedUser]);
  return (
    <div>
      <h1>Users</h1>
      <Button caption='Add new' onClick={()=>{
        set('selectedUser', undefined);
        setSelectedUser({...newUser});
        setFormOpened(true);
      }}/>
      <Grid
        source={usersSource}
        pagination={pagination}
        columns={columns}
        header
      />
      <Modal width={600} isOpen={formOpened} onClose={()=>{
        setFormOpened(false);
        set('selectedUser', undefined);
      }}>
        <div>
          <JsonForm fields={selectedUser._id ? editFields : createFields} value={selectedUser}
            onChange={setSelectedUser}/>
          <Button
            caption="Save"
            onClick={() => {
              if (!selectedUser._id) {
                usersSource.create([selectedUser]);
              } else {
                usersSource.update(selectedUser);
              }
            }}
          />
      <Button caption='Cancel' onClick={()=>{
        setFormOpened(false);
        set('selectedUser', undefined);
      }}/>
        </div>
      </Modal>
    </div>
  );
};
