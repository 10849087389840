import React from 'react';
import {ISource} from '../../sources/source';
import styles from './Grid.module.scss';
import classNames from 'classnames';

export interface IGridColumn<ItemType, KeyFieldType> {
  name: string;
  Template?: (props: {
    item: ItemType;
    name: string;
    renderRowIndex: number;
    source: ISource<ItemType, KeyFieldType>;
    onRowClick?: (item:ItemType)=>void;
  }) => JSX.Element;
}

interface IGridBaseRowProps<ItemType, KeyFieldType> {
  item: ItemType;
  renderIndex: number;
  columns: IGridColumn<ItemType, KeyFieldType>[];
  source: ISource<ItemType, KeyFieldType>;
  onRowClick?: (item:ItemType)=>void;
}

export const GridBaseRow = <ItemType, KeyFieldType>(
  props: IGridBaseRowProps<ItemType, KeyFieldType>
) => {
  return (
    <div className={classNames(styles.gridRow, props.renderIndex%2 ?
      styles.odd_row : '')}>
      {props.columns.map((column) => {
        const Template = column.Template;
        if (Template)
          return (
            <Template
              renderRowIndex={props.renderIndex}
              item={props.item}
              name={column.name}
              key={column.name}
              source={props.source}
              onRowClick={props.onRowClick}
            />
          );
        return (
          <div key={column.name} onClick={()=>{
            if (props.onRowClick)
              props.onRowClick(props.item);
          }}>
            {String(props.item[column.name as keyof ItemType])}
          </div>
        );
      })}
    </div>
  );
};
