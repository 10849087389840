import React, {useEffect, useMemo, useContext, Fragment, useState} from 'react';
import {createPortal} from 'react-dom';
import cn from 'classnames';
import styles from './Popup.module.scss';

// XXX: strategy
// top right bottom left (as margin/padding)
// auto - no fixes
// bind - connect with position
// f.e.: bind bind auto auto
export interface iPopupPropsBase {
  className?: string;
  contentWhenOpen?: boolean;
  onClose?: () => void;
  anchorElement: HTMLElement;
  parent: HTMLElement;
  strategy: string;
  offsetTop?: number;
  syncPosition?: number;
}
export interface iPopupProps extends iPopupPropsBase {
  children: JSX.Element;
  isOpen: boolean;
  onClose: () => void;
}

const getOffsetFromScreen = (el: HTMLElement) => {
  const base_rect = el.getBoundingClientRect();
  let top = base_rect.top;
  let left = base_rect.left;
  while (el.parentElement) {
    el = el.parentElement;
    if (el.classList.contains('relative')) {
      const styles_ = window.getComputedStyle(el);
      const lb = parseInt(styles_['borderLeftWidth']) || 0;
      const tb = parseInt(styles_['borderTopWidth']) || 0;
      const rect = el.getBoundingClientRect();
      top += rect.top + tb;
      left += rect.left + lb;
    }
    top += el.scrollTop;
  }
  return {top, left};
};

const applyStyles = (
  el: HTMLElement,
  anchor: HTMLElement,
  props: iPopupProps
) => {
  const {left, top} = getOffsetFromScreen(anchor);
  const width = anchor.offsetWidth;
  // const height = anchor.offsetTop;
  const str_array = props.strategy.split(' ');
  if (str_array[0] == 'bind') {
    const calc_top = Math.max(0, top + (props.offsetTop || 0));
    el.style.top = `${calc_top}px`;
  }
  if (str_array[1] == 'bind') el.style.width = `${width}px`;
  // if (str_array[2]=='bind')
  // not implemented
  if (str_array[3] == 'bind') el.style.left = `${left}px`;
  else if (str_array[3] == 'center') {
    el.style.left = `${left + width / 2}px`;
    el.style.transform = `translateX(-50%)`;
    console.log('center for el: ', left, width);
  }
};
export const Popup = (props: iPopupProps) => {
  const {children, isOpen, className, anchorElement, syncPosition} = props;
  const [state, setState] = useState<{el?: HTMLDivElement}>({el: undefined});
  useEffect(() => {
    const modal_root = document.getElementById('modalRoot');
    const el = document.createElement('div');
    if (!modal_root) return;
    modal_root.appendChild(el);
    el.className = styles.absPosition;
    applyStyles(el, anchorElement, props);
    setState({el});
    return () => {
      el.remove();
    };
  }, []);

  useEffect(() => {
    if (!state.el) return;
    state.el.style.display = isOpen ? 'block' : 'none';
    if (isOpen) applyStyles(state.el, anchorElement, props);
  }, [isOpen, state.el, syncPosition]);
  const rendered = (
    <div
      className={cn(styles.popupWindow, className)}
      onClick={event => event.stopPropagation()}
    >
      <div className={styles.content}
        tabIndex={0}>{children}</div>
    </div>
  );
  if (state.el) return createPortal(rendered, state.el);
  return <Fragment></Fragment>;
};
