import React, {useContext, useEffect, useMemo, useState} from 'react';
import {Grid} from '../../dimijs/src/lib/components/lists/Grid';
import gridStyles from '../../dimijs/src/lib/components/lists/Grid.module.scss';
import {getCollectionsSource} from '../../sources/collectionsSource';
import {Pagination} from '../../dimijs/src/lib/components/lists/Pagination';
import {ICollection} from '../../sources/types';
import {ICrudStatus, ISource} from '../../dimijs/src/lib/sources/source';
import { useLocationSearch } from '../../dimijs/src/lib/utils/locationSearch';
import { Button } from '../../dimijs/src/lib/components/Button';
import { Modal } from '../../dimijs/src/lib/components/Modal';
import { JsonForm } from '../../ui/JsonForm';
import {DataCtx} from '../../dimijs/src/lib/utils/dataContext';

const newCollection:ICollection = {
  fields: {},
  name: '',
}

export const Collections = () => {
  const {set, params} = useLocationSearch();
  const [selectedCollection, setSelectedCollection] = useState<ICollection>({
    ...newCollection,
  });
  const [formOpened, setFormOpened] = useState(false);
  const {store} = useContext(DataCtx);
  const collectionsSource = getCollectionsSource(store);
  const pagination = useMemo(() => {
    return {
      defaultCountPage: 10,
      queryParamCountOnPage: 'countCollectionsOnPage',
      queryParamPage: 'pageCollections',
    };
  }, []);
  const columns = useMemo(() => {
    return [
      {name: '_id'},
      {name: 'name', Template: (props: {
        item: ICollection;
        source: ISource<ICollection, string>;
        renderRowIndex: number;
      }) => {
        return (
          <div className={props.renderRowIndex % 2 ? gridStyles.odd_row : ''}>
            <Button caption={props.item.name} onClick={()=>{
              set('selectedCollection', props.item._id);
            }}/>
          </div>
        );
      },},
      {
        name: 'fields',
        Template: (props: {
          item: ICollection;
          source: ISource<ICollection, string>;
          renderRowIndex: number
        }) => {
          const keys = Object.keys(props.item.fields);
          return (
            <div className={props.renderRowIndex % 2 ? gridStyles.odd_row : ''}>
              {keys.map(f => (
                <>
                  {f}
                  <br />
                </>
              ))}
            </div>
          );
        },
      },
    ];
  }, []);
  const editFields = useMemo(()=>([
    {name: 'name', type: 'string' as const},
    {name: 'fields', type: 'json' as const},
  ]), []);
  useEffect(()=>{
    if (!params.selectedCollection) {
      return;
    }
    collectionsSource.source.read(params.selectedCollection).then(data=>{
      if ((data as ICrudStatus).errorCode) {
        setSelectedCollection({...newCollection});
        return;
      }
      if (data!==null) {
        setSelectedCollection(data as ICollection);
        setFormOpened(true);
      }
    });
  }, [params.selectedCollection]);
  return (
    <div>
      <h1>Collections</h1>
      <Button caption='Add new' onClick={()=>{
        set('selectedCollection', undefined);
        setSelectedCollection({...newCollection});
        setFormOpened(true);
      }}/>
      <Grid
        source={collectionsSource.source}
        pagination={pagination}
        columns={columns}
      />
      <Modal width={600} isOpen={formOpened} onClose={()=>{
        setFormOpened(false);
        set('selectedCollection', undefined);
      }}>
        <div>
          <JsonForm fields={editFields} value={selectedCollection}
            onChange={setSelectedCollection}/>
          <Button
            caption="Save"
            onClick={() => {
              if (!selectedCollection._id) {
                collectionsSource.source.create([selectedCollection]);
              } else {
                collectionsSource.source.update(selectedCollection);
              }
            }}
          />
      <Button caption='Cancel' onClick={()=>{
        setFormOpened(false);
        set('selectedCollection', undefined);
      }}/>
        </div>
      </Modal>
    </div>
  );
};
