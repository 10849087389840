import React from 'react';
import { Button } from '../../dimijs/src/lib/components/Button';
import { IBlockProps } from './Blocks';

export interface IAdditionalLinkBlockProps {
  href: string;
  text: string;
}
export interface ILinkBlockProps extends IBlockProps, IAdditionalLinkBlockProps {
  
}

export const Link = (props: ILinkBlockProps)=>{
  return <Button href={props.href} caption={props.text}/>;
};
