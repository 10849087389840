import React, { useState } from 'react';
import { Input } from '../dimijs/src/lib/components/Input';
import { Button } from '../dimijs/src/lib/components/Button';
import { useUserSource } from '../sources/useUserSource';
import { Link } from 'react-router-dom';

export const FormRegister = ()=>{
    const [login, setLogin] = useState('');
    const [pass, setPass] = useState('');
    const userSource = useUserSource();
    return <div>
      <h2>Register form</h2>
      <Link to={'/login'}>Login</Link>
      <form onSubmit={e=>e.preventDefault()}>
        <Input value={login} onChange={setLogin} label={'Login'}/>
        <Input value={pass} onChange={setPass} label={'Password'}/>
        <Button caption='Register' onClick={()=>{
            userSource.create([{login, pass}]).then(res=>{
                if (res.errorCode) {
                  
                }
            });
        }}/>
      </form>
    </div>;
};
