import React, {useMemo, useState} from 'react';
import {
    CrudFilterFieldTypes,
    CrudFilterOperator,
    ISource,
} from '../dimijs/src/lib/sources/source';
import {Grid} from '../dimijs/src/lib/components/lists/Grid';
import gridStyles from '../dimijs/src/lib/components/lists/Grid.module.scss';
import {Input} from '../dimijs/src/lib/components/Input';
import axios from 'axios';
import {Http} from '../dimijs/src/lib/sources/http';
import {IFile} from '../sources/types';
import {Button} from '../dimijs/src/lib/components/Button';

interface IImagesLibraryProps {
    onSelect: (image: string) => void;
}

export const ImagesLibrary = (props: IImagesLibraryProps) => {
    const [per, setUploadPercentage] = useState('0');
    const filesColumns = useMemo(() => {
        return [
            {name: 'filename'},
            {
                name: 'preview',
                Template: (p: {
                    item: IFile;
                    source: ISource<IFile, string>;
                    renderRowIndex: number;
                }) => {
                    return (
                        <div
                            className={
                                p.renderRowIndex % 2 ? gridStyles.odd_row : ''
                            }>
                            <img src={`/uploads/${p.item.filename}`} 
                              width={60} alt={p.item.filename}/>
                        </div>
                    );
                },
            },
            {
              name: 'controls',
              Template: (p: {
                  item: IFile;
                  source: ISource<IFile, string>;
                  renderRowIndex: number;
              }) => {
                  return (
                      <div
                          className={
                              p.renderRowIndex % 2 ? gridStyles.odd_row : ''
                          }>
                          <Button caption='Select' onClick={()=>{
                            props.onSelect(p.item.filename);
                          }}/>
                      </div>
                  );
              },
          },
        ];
    }, []);
    const filesPagination = useMemo(
        () => ({queryParamPage: 'page_files', defaultCountPage: 5}),
        [],
    );
    const [filename, setFilename] = useState('');
    const filesSource = useMemo(() => {
        const fSource = new Http<IFile, string>('_id');
        fSource.httpEndPoint = '/api/files';
        return fSource;
    }, []);
    const filesFilter = useMemo(() => {
        if (!filename) return [];
        return [
            {
                field: 'filename' as 'filename',
                value: filename,
                operator: CrudFilterOperator.LIKE,
                type: CrudFilterFieldTypes.Field as const,
            },
        ];
    }, [filename]);

    return (
        <div>
            Uploading: {per}% <br />
            <form
                action="/upload"
                encType="multipart/form-data"
                method="post"
                id="this_form"
                onSubmit={(ev) => {
                    ev.preventDefault();
                    const f = document.getElementById(
                        'this_form',
                    ) as HTMLFormElement;
                    if (!f) return;
                    const bodyFormData = new FormData(f);
                    axios
                        .post('/upload', bodyFormData, {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                            },
                            onUploadProgress: (progress) => {
                                const {total, loaded} = progress;
                                const totalSizeInMB = (total || 0) / 1000000;
                                const loadedSizeInMB = loaded / 1000000;
                                const uploadPercentage =
                                    (loadedSizeInMB / totalSizeInMB) * 100;
                                setUploadPercentage(
                                    uploadPercentage.toFixed(2),
                                );
                                console.log(
                                    'total size in MB ==> ',
                                    totalSizeInMB,
                                );
                                console.log(
                                    'uploaded size in MB ==> ',
                                    loadedSizeInMB,
                                );
                            },
                        })
                        .then((res) => {
                            console.log('DONE', res);
                            if (res && res.data) {
                                console.log(res.data.uploadedFileName);
                                filesSource.notifyAboutNewRow({
                                    _id: res.data.uploadedFileName,
                                    filename: res.data.uploadedFileName,
                                });
                            }
                        });
                }}>
                <div>
                    <input type="file" name="uploaded_file" />
                    <input
                        type="text"
                        placeholder="Number of speakers"
                        name="nspeakers"
                    />
                    <input type="submit" value="Get me the stats!" />
                </div>
            </form>
            <Input
                value={filename}
                onChange={setFilename}
                label="Filename filter"
            />
            <Grid
                source={filesSource}
                columns={filesColumns}
                pagination={filesPagination}
                header
                defaultFilter={filesFilter}
            />
        </div>
    );
};
