import {Http} from '../dimijs/src/lib/sources/http';
import {ICrudFilter, ICrudList, ICrudPagination, ICrudSorting} from '../dimijs/src/lib/sources/source';
import {ICollection} from './types';

export const getCollectionsSource = (store:any)=>{
  return store.collectionsSource as {
    inited: boolean,
    source: Http<ICollection, string>,
    sorting: ICrudSorting<ICollection>[],
    filter: ICrudFilter<ICollection>[],
    pagination: ICrudPagination,
  };
}

export const initCollectionsSource = (store:any, initProps)=>{
  store.collectionsSource = {
    inited: false,
    source: null as any,
    sorting: [],
    filter: [],
    pagination: {page: 0, countOnPage: 100},
  } as {
    inited: boolean,
    source: Http<ICollection, string>,
    sorting: ICrudSorting<ICollection>[],
    filter: ICrudFilter<ICollection>[],
    pagination: ICrudPagination,
  };
  store.collectionsSource.source = new Http<ICollection, string>('_id',
    // @ts-ignore
    initProps && initProps.collections ? {
      data: {
        // @ts-ignore
        data: initProps.collections,
      },
      filter: store.collectionsSource.filter,
      pagination: store.collectionsSource.pagination,
      sorting: store.collectionsSource.sorting,
    } : undefined);
    store.collectionsSource.source.httpEndPoint = '/api/collections';
};


export const getCollectionItemSource = (store:any, collectionId: string,
  serialized?: {
    data: ICrudList<any>,
    filter: ICrudFilter<any>[],
    pagination: ICrudPagination,
    sorting?: ICrudSorting<any>[]
  })=>{
  if (!store.collections)
    store.collections = {} as {[propname: string]:Http<any, string>};
  if (!store.collections[collectionId]) {
    store.collections[collectionId] = new Http<any, string>('_id');
    store.collections[collectionId].httpEndPoint = `/api/collections/${collectionId}`;
  }
  if (serialized) {
    store.collections[collectionId].addSerializedState(serialized.data,
      serialized.filter, serialized.pagination, serialized.sorting);
  }
  return store.collections[collectionId] as Http<any, string>;
};