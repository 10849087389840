import React from 'react';
import { IBlockProps } from './Blocks';

export interface IAdditionalImageBlockProps {
  image: string;
  alt: string;
}
export interface IImageBlockProps extends IBlockProps, IAdditionalImageBlockProps {
  
}

export const Image = (props: IImageBlockProps)=>{
  return <img src={`/uploads/${props.image}`} width={props.width}
    height={props.height} alt={props.alt}/>;
};
