import {Http} from '../dimijs/src/lib/sources/http';
import {ICrudFilter, ICrudPagination, ICrudSorting} from '../dimijs/src/lib/sources/source';
import {IPage} from './types';

export const getPageSource = (store:any)=>{
  return store.pagesSource as {
    inited: boolean,
    source: Http<IPage, string>,
    pagesSorting: ICrudSorting<IPage>[],
    pagesFilter: ICrudFilter<IPage>[],
    pagesPagination: ICrudPagination,
  };
};
export const initPageSource = (store:any, initProps)=>{
  store.pagesSource = {
    inited: false,
    source: null as any,
    pagesSorting: [],
    pagesFilter: [],
    pagesPagination: {page: 0, countOnPage: 100},
  };
  store.pagesSource.source = new Http<IPage, string>('_id',
    // @ts-ignore
    initProps && initProps.pages ? {
      data: {
        // @ts-ignore
        data: initProps.pages,
      },
      filter: store.pagesSource.pagesFilter,
      pagination: store.pagesSource.pagesPagination,
      sorting: store.pagesSource.pagesSorting,
    } : undefined);
  store.pagesSource.source.httpEndPoint = '/api/pages';
}

