import React, {Fragment, useContext, useEffect, useMemo, useState} from 'react';
import {Grid} from '../../dimijs/src/lib/components/lists/Grid';
import gridStyles from '../../dimijs/src/lib/components/lists/Grid.module.scss';
import {Http} from '../../dimijs/src/lib/sources/http';
import {
  getCollectionsSource,
  getCollectionItemSource,
} from '../../sources/collectionsSource';
import {
  CrudFilterFieldTypes,
  ICrudFilter,
  ICrudList,
  ICrudStatus,
  ISource,
} from '../../dimijs/src/lib/sources/source';
import {ICollection, ICollectionItem} from '../../sources/types';
import {DataCtx} from '../../dimijs/src/lib/utils/dataContext';
import {Button} from '../../dimijs/src/lib/components/Button';
import {useLocationSearch} from '../../dimijs/src/lib/utils/locationSearch';
import {Modal} from '../../dimijs/src/lib/components/Modal';
import {JsonForm} from '../JsonForm';
import {IconButton} from '../../dimijs/src/lib/components/IconButton';
import classNames from 'classnames';
import styles from './GridBlock.module.scss';

const getFiltersWithParams = (params: any, filter?: ICrudFilter<any>[])=>{
    if (!filter)
        return filter;
    return filter.map(f=>{
      if (f.type === CrudFilterFieldTypes.Field) {
        if (f.value.indexOf('qs=')>-1) {
          const qsParam = f.value.split('qs=')[1];
          return {...f, value: params[qsParam]};
        }
      }
      return f;
    }).filter(f=>f.type === CrudFilterFieldTypes.Field ? !!f.value : true);
}

export const GridBlock = (props: any) => {
  const dataContext = useContext(DataCtx);
  const dataId = DataLayerId(props);
  const {set, params} = useLocationSearch();

  useMemo(() => {
    dataContext.addDataLayer(
      () => dataId,
      () => DataLayer(dataContext.store, props, params),
    );
  }, []);
  const pagination = useMemo(() => {
    return {
      queryParamPage: props.queryParamPage,
      defaultCountPage: 10,
      queryParamCountOnPage: props.queryParamCountOnPage,
    };
  }, []);
  const source = getCollectionItemSource(
    dataContext.store,
    props.collection,
    dataContext.initProps[dataId]?.serializedData,
  );
  const [metaCollection, setMetaCollection] = useState(
    dataContext.initProps[dataId]?.metaCollection as ICollection,
  );
  const {columns, jsonFields} = useMemo(() => {
    if (!metaCollection) return {columns: [], jsonFields: []};
    const fields = Object.keys(metaCollection.fields);
    const columns = fields.map((f) => ({name: f, Template: undefined as any}));
    columns.push({
      name: 'rowOptions',
      Template: (p: {
        item: any;
        source: ISource<any, string>;
        renderRowIndex: number;
      }) => {
        return (
          <div className={classNames(p.renderRowIndex % 2 ? gridStyles.odd_row : '', styles.flex)}>
            <IconButton
              iconSetName={'action'}
              iconId={'search'}
              onClick={() => {
                set(`edit_${props.collection}`, p.item._id);
              }}
              caption={''}
            />
            {props.selectQueryParam && <Button caption='Select' onClick={()=>{
              set(props.selectQueryParam, p.item._id);
            }}/>}
          </div>
        );
      },
    });
    const jsonFields = fields.map((f) => ({
      name: f,
      type: 'string' as 'string',
    }));
    return {columns, jsonFields};
  }, [metaCollection]);
  const [newValue, setNewValue] = useState({});
  useEffect(() => {
    if (metaCollection) return;
    getCollectionsSource(dataContext.store)
      .source.list(
        [
          {
            field: 'name',
            value: props.collection,
            type: CrudFilterFieldTypes.Field,
          },
        ],
        {page: 0, countOnPage: 1},
      )
      .then((data) => {
        if ((data as ICrudStatus).errorCode) {
          return;
        }
        setMetaCollection((data as ICrudList<ICollection>).data[0]);
      });
  }, [metaCollection, props.collection]);
  const [editItem, setEditItem] = useState();
  useEffect(() => {
    if (!params[`edit_${props.collection}`]) {
      return;
    }
    getCollectionItemSource(dataContext.store, props.collection)
      .read(params[`edit_${props.collection}`])
      .then((data) => {
        if ((data as ICrudStatus).errorCode) {
          setEditItem(undefined);
          return;
        }
        setEditItem(data as any);
      });
  }, [params[`edit_${props.collection}`]]);
  return (
    <div>
      <Grid source={source} pagination={pagination} columns={columns}
        defaultFilter={getFiltersWithParams(params, props.defFilter)} header />
      <Button
        caption="Add"
        onClick={() => {
          set(`add_${props.collection}`, 'true');
        }}
      />
      <Modal isOpen={!!params[`add_${props.collection}`]} width={400}>
        <Fragment>
          <JsonForm
            fields={jsonFields}
            value={newValue}
            onChange={setNewValue}
          />
          <Button
            caption="Save"
            onClick={() => {
              getCollectionItemSource(dataContext.store, props.collection)
                .create([newValue])
                .then(() => {
                  set(`add_${props.collection}`, undefined);
                  setNewValue({});
                });
            }}
          />
          <Button
            caption="Cancel"
            onClick={() => {
              set(`add_${props.collection}`, undefined);
              setNewValue({});
            }}
          />
        </Fragment>
      </Modal>
      <Modal isOpen={!!params[`edit_${props.collection}`]} width={400}>
        <Fragment>
          {editItem && (
            <JsonForm
              fields={jsonFields}
              value={editItem}
              onChange={setEditItem}
            />
          )}
          <Button
            caption="Save"
            onClick={() => {
              if (!editItem) return;
              getCollectionItemSource(dataContext.store, props.collection)
                .update({...(editItem as any)})
                .then(() => {
                  set(`edit_${props.collection}`, undefined);
                });
            }}
          />
          <Button
            caption="Cancel"
            onClick={() => {
              set(`edit_${props.collection}`, undefined);
            }}
          />
        </Fragment>
      </Modal>
    </div>
  );
};

const DataLayerId = (props) => {
  return `${props.collection}`;
};

const DataLayer = async (store, props, params) => {
  const metaCollection = await getCollectionsSource(store).source.list(
    [
      {
        field: 'name',
        value: props.collection,
        type: CrudFilterFieldTypes.Field,
      },
    ],
    {page: 0, countOnPage: 1},
  );
  const collections = getCollectionItemSource(store, props.collection);
  if ((metaCollection as ICrudStatus).errorCode) {
    return {};
  }
  const items = await collections.list(
    props.defFilter || [],
    props.defPagination || {page: 0, countOnPage: 10},
    props.defSorting || [],
  );
  return {
    metaCollection: (metaCollection as ICrudList<ICollection>).data[0],
    serializedData: {
      data: items,
      filter: getFiltersWithParams(params, props.defFilter) || [],
      pagination: props.defPagination || {page: 0, countOnPage: 10},
      sorting: props.defSorting || [],
    },
  };
};
