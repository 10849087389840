import {createContext, useMemo, useRef} from 'react';
import {useLocationSearch} from './locationSearch';
interface ICtx {
  initProps: any,
  store: any,
  addDataLayer: (getId: ()=>string, getData: ()=>Promise<any>)=>void,
}
export const DataCtx = createContext<ICtx>({
  initProps: {},
  store: {},
  addDataLayer: ()=>{
    throw new Error('not inited')
  },
});

interface IDataContextProps {
  initProps: any;
  children: any;
  ssrDataCollector?: any;
  store: any;
}
export const DataContext = (props:IDataContextProps)=>{
  const ctxValue = useMemo<ICtx>(()=>{
    return {
      initProps: props.initProps,
      store: props.store,
      addDataLayer: (getId, getData)=>{
        if (props.ssrDataCollector) {
          props.ssrDataCollector(getId, getData);
        }
      },
    }
  }, []);
  return <DataCtx.Provider value={ctxValue}>
    {props.children}
  </DataCtx.Provider>;
};
