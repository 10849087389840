import { useMemo } from "react";
import { Http } from "../dimijs/src/lib/sources/http";

export interface IUser {
  _id?: string;
  login: string;
  pass?: string;
  role?: string;
}

export const useUserSource = ()=>{
    const source = useMemo(()=>{
        const source = new Http<IUser, string>('_id');
        source.httpEndPoint = '/api/users';
        return source;
    }, []);
    return source;
};

