import React, { useState } from 'react';
import { Input } from '../dimijs/src/lib/components/Input';
import { Button } from '../dimijs/src/lib/components/Button';
import { useUserSource } from '../sources/useUserSource';
import { Link } from 'react-router-dom';
import axios from 'axios';

const auth = async (username, password) => {
    try {
      const res = await axios.get('/authenticate', { auth: { username, password } });
      console.log(res.data);
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
};

export const FormLogin = ()=>{
    const [login, setLogin] = useState('');
    const [password, setPass] = useState('');
    return <div>
      <h2>Login form</h2>
      <Link to={'/form'}>Register</Link>
      <form onSubmit={e=>e.preventDefault()}>
        <Input value={login} onChange={setLogin} label={'Login'}/>
        <Input value={password} onChange={setPass} label={'Password'}/>
        <Button caption='Login' type={'submit'} onClick={()=>{
            auth(login, password).then((result)=>{
              if (result)
                  window.location.href = '/panel';
            });
        }}/>
      </form>
    </div>;
};
