import React from 'react';
import ReactDOM, {hydrateRoot} from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from 'react-router-dom';
import {initPageSource} from './sources/pageSource';
import {initCollectionsSource} from './sources/collectionsSource';
import {initBlocksSource} from './sources/blocksSource';

const rootHtml = document.getElementById('root') as HTMLElement;

window.store = {};
initPageSource(window.store, {...window.initProps});
initCollectionsSource(window.store, {...window.initProps});
initBlocksSource(window.store, {...window.initProps});
const appTree = <React.StrictMode>
  <App
    MainRouter={BrowserRouter}
    // @ts-ignore
    store={window.store}
    // @ts-ignore
    initProps={{...window.initProps}}/>
</React.StrictMode>;
if (rootHtml.children.length===0) {
  const root = ReactDOM.createRoot(rootHtml);
  root.render(appTree);
} else {
  hydrateRoot(rootHtml, appTree);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
