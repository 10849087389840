// LICENSE_CODE ZON
'use strict'; /*jslint ts:true, react: true*/
import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import styles from './Dropdown.module.scss';
import cn from 'classnames';
import {Input, IInputProps} from './Input';
import {
  CrudAggregation,
  CrudFilterFieldTypes,
  CrudFilterOperator,
  ICrudFilter,
  ICrudSorting,
  ISource,
} from '../sources/source';
import {useSource} from './lists/useSource';
import {Icon} from './Icon';
import {Popup} from './Popup';
import {Base} from './lists/Base';

type RemoveRedefinedFieldFromInput = {
  [Property in keyof IInputProps as Exclude<
    Property,
    'value' | 'onChange'
  >]: IInputProps[Property];
};

export interface IDropdownProps<ItemType, KeyFieldType>
  extends RemoveRedefinedFieldFromInput {
  source: ISource<ItemType, KeyFieldType>;
  value: KeyFieldType|undefined;
  filterFields: (keyof ItemType)[];
  onChange: (newValue: KeyFieldType|undefined) => void;
  onValueRender: (item: ItemType, selected: boolean) => string;
}

export const Dropdown: <ItemType, KeyFieldType>(
  props: IDropdownProps<ItemType, KeyFieldType>
) => JSX.Element = <ItemType, KeyFieldType>(
  props: IDropdownProps<ItemType, KeyFieldType>
) => {
  const {
    source,
    className,
    value,
    onValueRender,
    onFocus,
    onBlur,
    filterFields,
    ...inputProps
  } = props;
  const [filter, setFilter] = useState([] as ICrudFilter<ItemType>[]);
  const [inputValueForFilter, setInputValueForFilter] = useState<string|undefined>(undefined);
  const sorting = useMemo(() => [] as ICrudSorting<ItemType>[], []);
  const pagination = useMemo(
    () => ({
      page: 0,
      countOnPage: 100,
    }),
    []
  );
  const {list} = useSource(
    props.source,
    filter,
    pagination,
    sorting
  );
  const [focused, setFocused] = useState(false);
  const [opened, setOpened] = useState(false);
  const rootEl = useRef<HTMLDivElement>(null);
  const onChangeInternal = (new_value: ItemType) => {
    props.onChange(new_value[source.keyField] as KeyFieldType);
    setInputValueForFilter(undefined);
    setOpened(false);
  };
  useEffect(() => {
    if (inputValueForFilter) {
      const filterArray: ICrudFilter<ItemType>[] = [];
      filterFields.forEach(field => {
        filterArray.push({
          field: field,
          value: inputValueForFilter,
          type: CrudFilterFieldTypes.Field,
          operator: CrudFilterOperator.LIKE,
        });
      });
      if (filterArray.length > 1) {
        setFilter([
          {
            type: CrudFilterFieldTypes.Aggregation,
            filter: filterArray,
            aggregation: CrudAggregation.OR,
          },
        ]);
      } else setFilter(filterArray);
    } else setFilter([]);
  }, [inputValueForFilter]);
  useEffect(() => {
    if (focused) {
      setOpened(true);
    }
  }, [focused, inputValueForFilter]);
  const selectedItem = useMemo(() => {
    if (props.value===undefined)
        return;
    return list.find(d => d[source.keyField] === props.value);
  }, [props.value, list]);
  return (
    <div
      className={cn(
        styles.dropdownContainer,
        className,
        focused && styles.focused,
        opened ? styles.opened : styles.closed
      )}
      ref={rootEl}
      onMouseDown={() => {
        if (focused && opened) {
          setOpened(false);
        }
        if (focused && !opened) {
          setOpened(true);
        }
      }}
    >
      <Input
        {...inputProps}
        value={
          inputValueForFilter!==undefined ? inputValueForFilter :
          (selectedItem ? onValueRender(selectedItem, true) : '')
        }
        onChange={setInputValueForFilter}
        selectOnFocus
        iconAfter={
          <div className={cn(styles.chevronDown, opened && styles.rotated)}>
            <Icon setName={'navigation'} id={'chevronDown'}
              width={16} height={16}/>
          </div>
        }
        className={styles.inputInDropdown}
        onFocus={ev => {
          if (props.onFocus) props.onFocus(ev);
          setFocused(true);
        }}
        onBlur={ev => {
          if (props.onBlur) props.onBlur(ev);
          setFocused(false);
          setOpened(false);
          if (inputValueForFilter==='') {
            props.onChange(undefined);
            setInputValueForFilter(undefined);
          } else if (list && list.length===1) {
            onChangeInternal(list[0]);
            setInputValueForFilter(undefined);
          }
        }}
      />
      {rootEl.current && (
        <Popup
          isOpen={opened}
          onClose={() => setOpened(false)}
          anchorElement={rootEl.current}
          parent={rootEl.current.querySelector('input') as HTMLInputElement}
          strategy="bind bind auto bind"
          offsetTop={rootEl.current.offsetHeight + 4}
          className={styles.dropdownPopup}
        >
          <Base
            source={props.source}
            filter={filter}
            pagination={pagination}
            sorting={sorting}
            RowTemplate={row => {
              return (
                <div
                  className={styles.row}
                  tabIndex={0}
                  onMouseDown={() => {
                    onChangeInternal(row.item);
                  }}
                >
                  {onValueRender(row.item, false)}
                </div>
              );
            }}
          />
        </Popup>
      )}
    </div>
  );
};
