import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { useSource } from '../dimijs/src/lib/components/lists/useSource';
import { getPageSource } from '../sources/pageSource';
import { getBlocksSource } from '../sources/blocksSource';
import { CrudFilterFieldTypes, ICrudFilter, ICrudList, ICrudStatus } from '../dimijs/src/lib/sources/source';
import { IBlock, IPage } from '../sources/types';
import { BlockResolver, BlocksResolver } from '../ui/blocks/Blocks';
import {DataCtx} from '../dimijs/src/lib/utils/dataContext';

export const Page = ()=>{
  const location = useLocation();
  const {store, addDataLayer} = useContext(DataCtx);
  const pagesSource = getPageSource(store);
  const blocksSource = getBlocksSource(store);
  const [initialLoading, setInitialLoading] = useState(true);
  const {list, loading} = useSource(pagesSource.source,
    pagesSource.pagesFilter, pagesSource.pagesPagination,
    pagesSource.pagesSorting);
  useEffect(()=>{
    setInitialLoading(false);
  }, []);
  useMemo(()=>{
    addDataLayer(()=>'pages', async ()=>{
      const loadedData = await pagesSource.source.list(pagesSource.pagesFilter,
        pagesSource.pagesPagination, pagesSource.pagesSorting);
      if (loadedData && (loadedData as ICrudStatus).errorCode)
        return[];
      return (loadedData as ICrudList<IPage>).data;
    });
  }, []);
  const pageDescription = useMemo(()=>{
    const urlForResolve = location.pathname + location.search;
    const resolved = list.find(item=>{
      const rExp = new RegExp(item.resolve);
      return rExp.test(urlForResolve);
    });
    return resolved;
  }, [list, location.pathname, location.search]);
  const blockFilter = useMemo<ICrudFilter<IBlock>[]>(()=>{
    if (!pageDescription) {
        return [{
          field: 'page' as const,
          value: '-1',
          type: CrudFilterFieldTypes.Field,
        }];
    }
    return [{
      field: 'page' as const,
      value: pageDescription._id,
      type: CrudFilterFieldTypes.Field,
    }];
  }, [pageDescription]);
  const {list: listOfBlocks, loading: loadingBlocks} = useSource(blocksSource.source, blockFilter,
      blocksSource.pagination, blocksSource.sorting);
  useMemo(()=>{
    if (pageDescription && pageDescription._id) {
      addDataLayer(()=>'blocks', async ()=>{
        const loadedData = await blocksSource.source.list(blockFilter,
          blocksSource.pagination, blocksSource.sorting);
        if (loadedData && (loadedData as ICrudStatus).errorCode)
          return[];
        return {data: (loadedData as ICrudList<IBlock>).data,
          filter: blockFilter};
      });
    }
  }, [pageDescription, blockFilter, blocksSource, addDataLayer]);
  if (!pageDescription && !loading)
    return <div>404 NOT FOUND</div>;
  if (initialLoading && (loading || loadingBlocks)) {
    return <div>Loading...</div>;
  }
  return <div>
    <BlocksResolver blocksOnPage={listOfBlocks}/>
  </div>;
};
