import React, {Fragment, useEffect, useRef, useState} from 'react';
import {Input} from '../dimijs/src/lib/components/Input';
import {Button} from '../dimijs/src/lib/components/Button';
import styles from './StringifyObj.module.scss';
import {Modal} from '../dimijs/src/lib/components/Modal';
import classNames from 'classnames';
import {ImagesLibrary} from './ImagesLibrary';

interface IStringifyProps {
  value: any;
  onChange: (newValue: any) => void;
  label?: string;
  autoSave?: boolean;
}

export const StringifyObj = (props:IStringifyProps) => {
  const [value, setValueO] = useState([] as any);
  const setValue = newValue=>{
    setValueO(newValue);
    if (props.autoSave) {
      try {
        props.onChange(newValue);
      } catch (e: any) {
        alert(e.message);
      }
    }
  }
  useEffect(() => {
    setValueO(props.value);
  }, [JSON.stringify(props.value)]);
  const isArray = Array.isArray(value);
  const iterable = isArray ? value : Object.keys(value);
  const [addNew, setAddNew] = useState(false);
  const [newName, setNewName] = useState('');
  const [newValue, setNewValue] = useState('');
  const [isSelectImage, setIsSelectImage] = useState(false);
  const onSelectClb = useRef({
    fn: (filename:string)=>{},
  });
  return (
    <div className={styles.root}>
      {props.label}:<br/>
      <div className={classNames(styles.fields, iterable.length>0 && styles.fields_not_empty)}>
      {iterable.map((a, b)=>{
          const key = isArray ? b : a;
          const onChange = newValue=>{
            value[key] = newValue;
            if (isArray) {
              setValue([...value]);
            } else {
              setValue({...value});
            }
          };
          return <div key={key} className={styles.obj_item}>
            {key==='image' ? 
              <Fragment>
                <Button caption={value[key] ? `Selected: ${value[key]}`
                  : 'Click to select'} onClick={()=>{
                    onSelectClb.current.fn = (filename)=>{
                      onChange(filename);
                      setIsSelectImage(false);
                    }
                    setIsSelectImage(true);
                  }}/>
              </Fragment>
              : value[key] && typeof value[key] === 'object' ? <StringifyObj
              value={value[key]}
              onChange={onChange}
              autoSave
              label={key}/> :
              <Input label={key} value={value[key]} onChange={onChange} />}
              <Button
                caption="X"
                onClick={() => {
                  if (isArray) {
                    setValue(value.slice(0, key).concat(value.slice(key+1)));
                  } else {
                    delete value[key];
                    setValue({...value});
                  }
                }}
              />
          </div>;
      })}
      </div>
      <Button caption='+' onClick={()=>{
        setAddNew(true);
      }}/>
      <Modal isOpen={isSelectImage} width={800}>
        <ImagesLibrary onSelect={filename=>onSelectClb.current.fn(filename)} />
      </Modal>
      <Modal isOpen={addNew} width={200}>
        <>
          {isArray ? null : <Input label={'name'} value={newName} onChange={setNewName} />}<br/>
          <Input label={'value'} value={newValue} onChange={setNewValue} /><br/>
          <Button caption='Save' onClick={()=>{
            let parsed = newValue;
            try {
              parsed = JSON.parse(newValue);
            } catch(e){}
            if (isArray) {
              setValue([...value, parsed]);
            } else {
              setValue({...value, [newName]: parsed});
            }
            setNewValue('');
            setNewName('');
            setAddNew(false);
          }}/>
        </>
      </Modal>
      {JSON.stringify(value) !== JSON.stringify(props.value) ? (
        <>
          <br/>
          <Button
            caption="Save"
            onClick={() => {
              try {
                props.onChange(value);
              } catch (e: any) {
                alert(e.message);
              }
            }}
          />
          <Button
            caption="Cancel"
            onClick={() => {
              setValue(props.value);
            }}
          />
        </>
      ) : (
        <></>
      )}
    </div>
  );
};