import React, {useEffect, useState} from 'react';
import {Input} from '../dimijs/src/lib/components/Input';
import {Button} from '../dimijs/src/lib/components/Button';
import {StringifyObj} from './StringifyObj';
import {Dropdown} from '../dimijs/src/lib/components/Dropdown';
import {ISource} from '../dimijs/src/lib/sources/source';
import styles from './JsonForm.module.scss';
import {MultiSelect} from '../dimijs/src/lib/components/MultiSelect';

interface IJsonValue {
  [propname: string]: any;
}
interface IJsonFromProps<ItemType> {
  fields: {
    name: string;
    type: 'string' | 'number' | 'json' | 'dropdown' | 'multiselect';
    values?: ISource<any, any>;
    viewField?: string;
  }[];
  value: ItemType;
  onChange: (value: ItemType) => void;
}

export const JsonForm = <ItemType,>(props: IJsonFromProps<ItemType>) => {
  return (
    <div className={styles.form}>
      {props.fields.map(field => {
        if (field.type === 'number') {
          return (
            <div key={field.name}>
              <Input
                label={field.name}
                type="number"
                value={props.value[field.name]}
                onChange={newValue => {
                  props.onChange({...props.value, [field.name]: newValue});
                }}
              />
            </div>
          );
        }
        if (field.type === 'json') {
          return (
            <div key={field.name}>
              <StringifyObj
                label={field.name}
                autoSave
                value={props.value[field.name]}
                onChange={newValue => {
                  if (JSON.stringify(newValue)===JSON.stringify(props.value[field.name]))
                    return;
                  props.onChange({...props.value, [field.name]: newValue});
                }}
              />
            </div>
          );
        }
        if (field.type === 'dropdown' && field.values) {
          return (
            <div key={field.name}>
              <Dropdown
                label={field.name}
                source={field.values}
                filterFields={['id', 'title']}
                value={props.value[field.name]}
                onChange={newValue => {
                  props.onChange({...props.value, [field.name]: newValue || null});
                }}
                onValueRender={item => {
                    const basicTitle = item.title||(field.values ? item[field.values.keyField] : '');
                    return field.viewField ?
                      item[field.viewField]||basicTitle : basicTitle;
                }}
              />
            </div>
          );
        }
        if (field.type === 'multiselect' && field.values) {
          const isString = typeof props.value[field.name] === 'string';
          const valAsArray =  isString ?
            props.value[field.name].split(',') : props.value[field.name];
          return (
            <div key={field.name}>
              <MultiSelect
                label={field.name}
                source={field.values}
                filterFields={['id', 'title']}
                value={valAsArray}
                onChange={newValue => {
                  props.onChange({...props.value, [field.name]: isString
                    ? newValue.join(',') : newValue});
                }}
                onValueRender={item => item.title||item.id}
              />
            </div>
          );
        }
        return (
          <div key={field.name}>
            <Input
              label={field.name}
              value={props.value[field.name]}
              onChange={newValue => {
                props.onChange({...props.value, [field.name]: newValue});
              }}
            />
          </div>
        );
      })}
    </div>
  );
};
