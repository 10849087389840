import {useEffect, useState} from 'react';

export const getWidth = () => typeof document === 'undefined' ? 0 :
  window.visualViewport?.width ||
  window.innerWidth ||
  (document.documentElement && document.documentElement.clientWidth) ||
  (document.body && document.body.clientWidth);
export const getHeight = () => typeof document === 'undefined' ? 0 :
  window.visualViewport?.height ||
  window.innerHeight ||
  (document.documentElement && document.documentElement.clientHeight) ||
  (document.body && document.body.clientHeight);

export const useScreen = () => {
  const [width, setWidth] = useState(getWidth());
  const [height, setHeight] = useState(getHeight());
  useEffect(() => {
    const handleResize = () => {
      setWidth(getWidth());
      setHeight(getHeight());
    };
    window.addEventListener('resize', handleResize);
    if (window.visualViewport)
      window.visualViewport.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      if (window.visualViewport) {
        window.visualViewport.removeEventListener('resize', handleResize);
      }
    };
  }, []);
  const screen =
    width >= 1200
      ? 'xl'
      : width >= 992
      ? 'lg'
      : width >= 768
      ? 'md'
      : width >= 640
      ? 'sm'
      : 'xs';
  return {width, height, screen};
};
