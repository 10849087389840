import React, {useContext, useState} from 'react';
import {IBlockProps} from './Blocks';
import {DataCtx} from '../../dimijs/src/lib/utils/dataContext';
import styles from './Header.module.scss';
import classNames from 'classnames';
import {Button} from '../../dimijs/src/lib/components/Button';
import {Modal} from '../../dimijs/src/lib/components/Modal';
import {Login} from './Login';

interface IHeaderPorps extends IBlockProps {
  children?: JSX.Element;
}
// TODO:: loginTitle, signupTitle should be in props
export const Header = (props: IHeaderPorps) => {
  const {initProps} = useContext(DataCtx);
  const [loginVisible, setLoginVisible] = useState(false);
  return (
    <div className={classNames('container', styles.header)}>
      <div className={styles.logo}></div>
      <div className={styles.menu}>{props.children}</div>
      <div className={styles.user}>
        {initProps.user ? (
          <p>
            Hi, {initProps.user}. <a href="/logout">Logout</a><br/>
            <a href='/p/cp/'>Control Panel</a>
          </p>
        ) : (
          <p>
            <Button caption="Login" onClick={() => setLoginVisible(true)} />
          </p>
        )}
      </div>
      <Modal
        isOpen={loginVisible}
        onClose={() => setLoginVisible(false)}
        width={300}
        contentWhenOpen>
        <Login loginTitle={'Login'} signupTitle={'Sign up'} />
      </Modal>
    </div>
  );
};
