import React, {Fragment, useContext, useEffect, useMemo, useState} from 'react';
import {getPageSource} from '../sources/pageSource';
import {Input} from '../dimijs/src/lib/components/Input';
import {Button} from '../dimijs/src/lib/components/Button';
import {IBlock, IPage} from '../sources/types';
import {CrudEvents, CrudFilterFieldTypes, ICrudFilter, ICrudStatus, ISource} from '../dimijs/src/lib/sources/source';
import {useLocationSearch} from '../dimijs/src/lib/utils/locationSearch';
import { Grid } from '../dimijs/src/lib/components/lists/Grid';
import { IconButton } from '../dimijs/src/lib/components/IconButton';
import { getBlocksSource } from '../sources/blocksSource';
import { BlockForm } from './BlockForm';
import {Modal} from '../dimijs/src/lib/components/Modal';
import {DataCtx} from '../dimijs/src/lib/utils/dataContext';
import gridStyles from '../dimijs/src/lib/components/lists/Grid.module.scss';
interface IFieldFormProps {
  item?: IPage;
}

export const FieldForm = (props: IFieldFormProps) => {
  const {params, set} = useLocationSearch();
  const {_id} = params;
  const {store} = useContext(DataCtx);
  const blocksSource = getBlocksSource(store);
  const [item, setItem] = useState<IPage|undefined>(props.item);
  const gridFiltration = useMemo<ICrudFilter<IBlock>[]>(()=>{
    return [{
      field: 'page' as const,
      value: _id,
      type: CrudFilterFieldTypes.Field
    }];
  }, [_id]);
  const gridPagination = useMemo(
    () => ({queryParamPage: 'blocks_page', defaultCountPage: 30}),
    []
  );
  useEffect(()=>{
    return ()=>{
      set('blocks_page', undefined);
    };
  }, []);
  const gridColumns = useMemo(() => {
    return [
      {
        name: 'label',
        Template: (props: {
          item: IBlock;
          source: ISource<IBlock, string>;
          renderRowIndex: number;
        }) => {
          return (
            <div className={props.renderRowIndex % 2 ? gridStyles.odd_row : ''}>
              {props.item.label || props.item._id}
            </div>
          );
        },
      },
      {name: 'blockType'},
      {name: 'order'},
      {name: 'parent'},
      {name: 'page'},
      {name: 'zones'},
      {
        name: 'props',
        Template: (props: {item: IBlock; source: ISource<IBlock, string>,
          renderRowIndex: number}) => {
          return (
            <div className={props.renderRowIndex%2 ? gridStyles.odd_row : ''}>
              {JSON.stringify(props.item?.props)}
            </div>
          );
        },
      },
      {
        name: 'rowOptions',
        Template: (props: {item: IBlock; source: ISource<IBlock, string>,
          renderRowIndex: number}) => {
          return (
            <div className={props.renderRowIndex%2 ? gridStyles.odd_row : ''}>
              <IconButton
                iconSetName={'action'}
                iconId={'search'}
                onClick={() => {
                  set('edit_block', props.item._id);
                }}
                caption={''}
              />
              <IconButton
                iconSetName={'action'}
                iconId={'delete'}
                onClick={() => {
                    if (props.item._id)
                        props.source.delete(props.item._id);
                }}
                caption={''}
              />
            </div>
          );
        },
      },
    ];
  }, [set]);
  useEffect(()=>{
    if (!_id) {
      setItem(undefined);
      return;
    }
    getPageSource(store).source.read(_id).then(data=>{
      if ((data as ICrudStatus).errorCode) {
        setItem(undefined);
        return;
      }
      setItem(data as IPage);
    });
  }, [_id]);
  // useEffect(() => {
  //   return pagesSource.on(CrudEvents.onCreate, (event, data) => {
  //     set('_id', data[0]._id);
  //   });
  // }, []);
  return (
    <div>
      {item ? <div>
        <h2>Blocks</h2>
        <Button caption='Add new block' onClick={()=>{
          set('add_new_block', 'true');
        }}/>
        <Grid<IBlock, string>
          source={blocksSource.source}
          defaultSorting={blocksSource.sorting}
          pagination={gridPagination}
          columns={gridColumns}
          defaultFilter={gridFiltration}
          header
        />
        <Modal isOpen={!!params.add_new_block} width={600} onClose={()=>{
          set('add_new_block', undefined);
        }}>
          <BlockForm onSave={()=>{
            set('add_new_block', undefined);
          }} onCancel={()=>{
            set('add_new_block', undefined);
          }} page={_id}/>
        </Modal>
        <Modal isOpen={!!params.edit_block} width={600} onClose={()=>{
          set('edit_block', undefined);
        }}>
          <BlockForm id={params.edit_block} onSave={()=>{
            set('edit_block', undefined);
          }} onCancel={()=>{
            set('edit_block', undefined);
          }} page={_id}/>
        </Modal>
      </div> : null}
    </div>
  );
};
